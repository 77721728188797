(function() {
  if (!this.require) {
    var modules = {}, cache = {};

    var require = function(name, root) {
      var path = expand(root, name), indexPath = expand(path, './index'), module, fn;
      module   = cache[path] || cache[indexPath];
      if (module) {
        return module;
      } else if (fn = modules[path] || modules[path = indexPath]) {
        module = {id: path, exports: {}};
        cache[path] = module.exports;
        fn(module.exports, function(name) {
          return require(name, dirname(path));
        }, module);
        return cache[path] = module.exports;
      } else {
        throw 'module ' + name + ' not found';
      }
    };

    var expand = function(root, name) {
      var results = [], parts, part;
      // If path is relative
      if (/^\.\.?(\/|$)/.test(name)) {
        parts = [root, name].join('/').split('/');
      } else {
        parts = name.split('/');
      }
      for (var i = 0, length = parts.length; i < length; i++) {
        part = parts[i];
        if (part == '..') {
          results.pop();
        } else if (part != '.' && part != '') {
          results.push(part);
        }
      }
      return results.join('/');
    };

    var dirname = function(path) {
      return path.split('/').slice(0, -1).join('/');
    };

    this.require = function(name) {
      return require(name, '');
    };

    this.require.define = function(bundle) {
      for (var key in bundle) {
        modules[key] = bundle[key];
      }
    };

    this.require.modules = modules;
    this.require.cache   = cache;
  }

  return this.require;
}).call(this);
this.require.define({"_modules/top/BgRect":function(exports, require, module){(function() {
  var BgRect,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  BgRect = (function() {
    function BgRect(config) {
      this.config = config;
      this.onResize = bind(this.onResize, this);
      this.setEvents = bind(this.setEvents, this);
      this.setSizePosition = bind(this.setSizePosition, this);
      this.setTrigger = bind(this.setTrigger, this);
      this.createRect = bind(this.createRect, this);
      this.setup = bind(this.setup, this);
      this.$container = $('#contests');
      this.data = [];
      this.setup();
      this.setEvents();
    }

    BgRect.prototype.setup = function() {
      this.createRect();
      return this.setSizePosition();
    };

    BgRect.prototype.createRect = function() {
      return $('.contest').each((function(_this) {
        return function(i, el) {
          var d;
          d = {
            container: $(el),
            summary: $(el).find('.contest-summary'),
            concept: $(el).find('.contest-concept'),
            information: $(el).find('.contest-information'),
            rect1: $('<div class="bg-rect bg-rect-concept"></div>').clone(),
            rect2: $('<div class="bg-rect bg-rect-information"></div>').clone()
          };
          _this.$container.append(d.rect1);
          _this.$container.append(d.rect2);
          _this.setTrigger(d.summary[0]);
          _this.setTrigger(d.information[0]);
          return _this.data[i] = d;
        };
      })(this));
    };

    BgRect.prototype.setTrigger = function(el) {
      return ScrollTrigger.create({
        trigger: el,
        start: 'top bottom+=20%',
        onEnter: (function(_this) {
          return function() {
            return _this.setSizePosition();
          };
        })(this)
      });
    };

    BgRect.prototype.setSizePosition = function() {
      return _.each(this.data, (function(_this) {
        return function(d) {
          var summaryHeight, top;
          summaryHeight = d.summary.height();
          top = d.container.position().top + (d.container.outerHeight() - d.container.height()) + summaryHeight * 0.5;
          d.rect1.css({
            height: summaryHeight * 0.5 + d.concept.outerHeight(),
            top: top
          });
          return d.rect2.css({
            height: d.information.outerHeight(),
            top: d.container.position().top + d.information.position().top
          });
        };
      })(this));
    };

    BgRect.prototype.setEvents = function() {
      return $$.resizeSignal.add(this.onResize);
    };

    BgRect.prototype.onResize = function(w, h) {
      return this.setSizePosition();
    };

    return BgRect;

  })();

  module.exports = BgRect;

}).call(this);
;}});
this.require.define({"_modules/top/CircleLine":function(exports, require, module){(function() {
  var CircleLine,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  CircleLine = (function() {
    function CircleLine(config) {
      this.config = config;
      this.onResize = bind(this.onResize, this);
      this.onScroll = bind(this.onScroll, this);
      this.onModeChangeSignal = bind(this.onModeChangeSignal, this);
      this.play = bind(this.play, this);
      this.setup = bind(this.setup, this);
      this.$circles = this.config.target;
      this.setup();
    }

    CircleLine.prototype.setup = function() {};

    CircleLine.prototype.play = function(index, tl, time) {};

    CircleLine.prototype.onModeChangeSignal = function(mode) {};

    CircleLine.prototype.onScroll = function(st) {};

    CircleLine.prototype.onResize = function(w, h) {};

    return CircleLine;

  })();

  module.exports = CircleLine;

}).call(this);
;}});
this.require.define({"_modules/top/ConceptMotion":function(exports, require, module){(function() {
  var ConceptMotion,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  ConceptMotion = (function() {
    function ConceptMotion(config) {
      this.config = config;
      this.setup = bind(this.setup, this);
      this.setup();
    }

    ConceptMotion.prototype.setup = function() {};

    return ConceptMotion;

  })();

  module.exports = ConceptMotion;

}).call(this);
;}});
this.require.define({"_modules/top/Contests":function(exports, require, module){(function() {
  var Contests, ContestsPosition,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  ContestsPosition = require('_modules/top/ContestsPosition');

  Contests = (function() {
    function Contests(config) {
      this.config = config;
      this.moveImage = bind(this.moveImage, this);
      this.setup = bind(this.setup, this);
      this.setup();
    }

    Contests.prototype.setup = function() {
      $$.ContestsPosition = new ContestsPosition();
      return this.moveImage();
    };

    Contests.prototype.moveImage = function() {
      if ($$.mode === 'pc') {
        return;
      }
      return $('.contests-index-item').each((function(_this) {
        return function(i, el) {
          var image;
          image = $(el).find('.contests-index-image');
          return $(el).find('.contests-index-title').after(image);
        };
      })(this));
    };

    return Contests;

  })();

  module.exports = Contests;

}).call(this);
;}});
this.require.define({"_modules/top/ContestsPosition":function(exports, require, module){(function() {
  var ContestsPosition,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  ContestsPosition = (function() {
    function ContestsPosition(config) {
      this.config = config;
      this.onResize = bind(this.onResize, this);
      this.setMotion = bind(this.setMotion, this);
      this.setup = bind(this.setup, this);
      this.$container = $('.contests-index');
      this.$scroller = $('.contests-index-scroller');
      this.setup();
    }

    ContestsPosition.prototype.setup = function() {
      if ($$.mode === 'sp') {
        return;
      }
      $$.resizeSignal.add(this.onResize);
      this.onResize();
      return this.setMotion();
    };

    ContestsPosition.prototype.setMotion = function() {
      return gsap.to(this.$scroller, {
        x: (function(_this) {
          return function() {
            return -_this.scrollLength;
          };
        })(this),
        ease: 'none',
        scrollTrigger: {
          trigger: this.$scroller,
          start: 'center center',
          end: (function(_this) {
            return function() {
              return '+=' + _this.scrollLength;
            };
          })(this),
          scrub: true,
          pin: true,
          anticipatePin: 1,
          invalidateOnRefresh: true
        }
      });
    };

    ContestsPosition.prototype.onResize = function(w, h) {
      this.scrollerHeight = this.$scroller.outerHeight();
      this.scrollLength = this.$scroller.outerWidth() - this.$container.outerWidth();
      return this.$container.height(this.scrollerHeight + this.scrollLength);
    };

    return ContestsPosition;

  })();

  module.exports = ContestsPosition;

}).call(this);
;}});
this.require.define({"_modules/top/GnavProgress":function(exports, require, module){(function() {
  var GnavProgress,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  GnavProgress = (function() {
    function GnavProgress(config) {
      var itemSelector;
      this.config = config;
      this.deactiveItems = bind(this.deactiveItems, this);
      this.activateItem = bind(this.activateItem, this);
      this.setTriggers = bind(this.setTriggers, this);
      this.setup = bind(this.setup, this);
      this.ids = ['concept', 'contests'];
      itemSelector = _.map(this.ids, (function(_this) {
        return function(id) {
          return '.gnav-anchor-' + id;
        };
      })(this));
      this.$gnavItems = $(itemSelector.join());
    }

    GnavProgress.prototype.setup = function() {
      return this.setTriggers();
    };

    GnavProgress.prototype.setTriggers = function() {
      return _.each(this.ids, (function(_this) {
        return function(id, i) {
          var o;
          o = {
            id: id + 'progress',
            trigger: '#' + id,
            start: 'top 50%',
            end: 'bottom 50%',
            scrub: true,
            onEnter: function() {
              return _this.activateItem(i);
            },
            onLeave: function() {
              return _this.deactiveItems(i);
            },
            onLeaveBack: function() {
              return _this.deactiveItems(i);
            },
            onEnterBack: function() {
              return _this.activateItem(i);
            }
          };
          if (id === 'concept') {
            o.start = 'top 80%';
          }
          return ScrollTrigger.create(o);
        };
      })(this));
    };

    GnavProgress.prototype.activateItem = function(i) {
      return _.delay((function(_this) {
        return function() {
          return _this.$gnavItems.removeClass('-active').eq(i).addClass('-active');
        };
      })(this), 20);
    };

    GnavProgress.prototype.deactiveItems = function() {
      return this.$gnavItems.removeClass('-active');
    };

    return GnavProgress;

  })();

  module.exports = GnavProgress;

}).call(this);
;}});
this.require.define({"_modules/top/GrandFather":function(exports, require, module){(function() {
  var BgRect, Contests, GnavProgress, GrandFather, KV, Main, News, ScrollDown, ScrollEvents, WatchWheelDirection,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  KV = require('_modules/top/KV');

  ScrollEvents = require('_modules/top/ScrollEvents');

  GnavProgress = require('_modules/top/GnavProgress');

  Main = require('_modules/top/Main');

  ScrollDown = require('_modules/top/ScrollDown');

  WatchWheelDirection = require('_modules/top/WatchWheelDirection');

  Contests = require('_modules/top/Contests');

  News = require('_modules/top/News');

  BgRect = require('_modules/top/BgRect');

  GrandFather = (function() {
    function GrandFather(config) {
      this.config = config;
      this.fragmentScroll = bind(this.fragmentScroll, this);
      this.hasFragment = bind(this.hasFragment, this);
      this.setAnchorScroll = bind(this.setAnchorScroll, this);
      this.setModules = bind(this.setModules, this);
      this.allImagesLoaded = bind(this.allImagesLoaded, this);
      this.createForceLoadObject = bind(this.createForceLoadObject, this);
      this.forceLoadLazyImages = bind(this.forceLoadLazyImages, this);
      this.onActived = bind(this.onActived, this);
      this.onInitialAssetsLoaded = bind(this.onInitialAssetsLoaded, this);
      this.setEvents = bind(this.setEvents, this);
      this.setup = bind(this.setup, this);
      this.$html = $('html');
      this.setup();
      this.setEvents();
    }

    GrandFather.prototype.setup = function() {
      $$.WatchWheelDirection = new WatchWheelDirection();
      $$.KV = new KV();
      $$.ScrollEvents = new ScrollEvents();
      $$.GnavProgress = new GnavProgress();
      $$.Main = new Main();
      $$.ScrollDown = new ScrollDown();
      $$.Contests = new Contests();
      $$.News = new News();
      $$.BgRect = new BgRect();
      $$.MainAppearMotion.setup();
      return this.setModules();

      /* debug */
    };

    GrandFather.prototype.setEvents = function() {
      return $$.initialAssetsLoadedSignal.add(this.onInitialAssetsLoaded);
    };

    GrandFather.prototype.onInitialAssetsLoaded = function() {
      $$.initialAssetsLoadedSignal.remove(this.onInitialAssetsLoaded);
      this.onActived();
      return this.setAnchorScroll();
    };

    GrandFather.prototype.onActived = function() {
      this.$html.removeClass('-loading -hide-loading');
      $('html').scrollTop(0);
      this.forceLoadLazyImages().then(this.allImagesLoaded);
      $$.GnavProgress.setup();
      $$.MotionsOnScroll.init();
      $$.MainAppearMotion.appear();
      _.delay((function(_this) {
        return function() {
          $('html').scrollTop(0);
          return $$.ScrolledToFoot.setup();
        };
      })(this), 100);
      return _.delay((function(_this) {
        return function() {
          $(window).trigger('resize');
          $$.ScrollTriggerAll.refresh();
          return _this.hasFragment();
        };
      })(this), 1000);
    };

    GrandFather.prototype.forceLoadLazyImages = function() {
      return this.createForceLoadObject($('img.lazy').not('.lazyloading,.lazyloaded'));
    };

    GrandFather.prototype.createForceLoadObject = function(el) {
      var d, t;
      d = $.Deferred();
      t = el;
      t.each((function(_this) {
        return function(i, el) {
          return lazySizes.loader.unveil(el);
        };
      })(this));
      t.imagesLoaded().always((function(_this) {
        return function(instance) {
          return d.resolve();
        };
      })(this));
      return d.promise();
    };

    GrandFather.prototype.allImagesLoaded = function() {
      return $(window).trigger('resize');
    };

    GrandFather.prototype.setModules = function() {};

    GrandFather.prototype.setAnchorScroll = function() {
      return $('.js-anchor-local').pageScroll();
    };

    GrandFather.prototype.hasFragment = function() {
      if (!$$.Common.fragment) {
        return;
      }
      return this.fragmentScroll();
    };

    GrandFather.prototype.fragmentScroll = function() {
      var fragment;
      if ($$.Common.fragment.match(/anchor-/)) {
        fragment = $$.Common.fragment.replace('anchor-', '');
      } else {
        fragment = $$.Common.fragment;
      }
      return $('.gnav-anchor-' + fragment).click();
    };

    return GrandFather;

  })();

  module.exports = GrandFather;

}).call(this);
;}});
this.require.define({"_modules/top/KV":function(exports, require, module){(function() {
  var KV, KVHideMotion, KVIgnore, KVVideo,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  KVIgnore = require('_modules/top/KVIgnore');

  KVVideo = require('_modules/top/KVVideo');

  KVHideMotion = require('_modules/top/KVHideMotion');

  KV = (function() {
    function KV(config) {
      this.config = config;
      this.onWheel = bind(this.onWheel, this);
      this.playForConcept = bind(this.playForConcept, this);
      this.stopV2Loop = bind(this.stopV2Loop, this);
      this.startV2Loop = bind(this.startV2Loop, this);
      this.initStartV2Loop = bind(this.initStartV2Loop, this);
      this.appear = bind(this.appear, this);
      this.onV3Loaded = bind(this.onV3Loaded, this);
      this.onV1Loaded = bind(this.onV1Loaded, this);
      this.setEventsSecond = bind(this.setEventsSecond, this);
      this.setEvents = bind(this.setEvents, this);
      this.setVideoSecond = bind(this.setVideoSecond, this);
      this.setVideo = bind(this.setVideo, this);
      this.setup = bind(this.setup, this);
      this.$container = $('#kv');
      this.ready = $.Deferred();
      this.setup();
    }

    KV.prototype.setup = function() {
      $$.KVIgnore = new KVIgnore();
      $$.KVHideMotion = new KVHideMotion();
      if ($$.KVIgnore.isPlayed) {
        this.setVideoSecond();
        return this.setEventsSecond();
      } else {
        this.setVideo();
        return this.setEvents();
      }
    };

    KV.prototype.setVideo = function() {
      this.v1 = new KVVideo({
        src: './assets/images/top/kv/kv1.mp4',
        srcSp: './assets/images/top/kv/kv1-sp.mp4',
        video: this.$container.find('.kv-video')[0],
        poster: this.$container.find('.kv-poster img')[0]
      });
      this.v2 = new KVVideo({
        src: './assets/images/top/kv/kv2.mp4',
        srcSp: './assets/images/top/kv/kv2-sp.mp4',
        video: this.$container.find('.kv-video2')[0]
      });
      this.v3 = new KVVideo({
        src: './assets/images/top/kv/kv3.mp4',
        srcSp: './assets/images/top/kv/kv3-sp.mp4',
        video: this.$container.find('.kv-video3')[0],
        poster: this.$container.find('.kv-poster img')[0]
      });
      this.v3.setListenerCurrentTime();
      this.v1.hide();
      this.v2.hide();
      return this.v3.hide();
    };

    KV.prototype.setVideoSecond = function() {
      this.v3 = new KVVideo({
        src: './assets/images/top/kv/kv3.mp4',
        srcSp: './assets/images/top/kv/kv3-sp.mp4',
        video: this.$container.find('.kv-video3')[0]
      });
      return this.v3.hide();
    };

    KV.prototype.setEvents = function() {
      this.v1.ready.then(this.onV1Loaded);
      $$.WatchWheelDirection.start();
      return $$.WatchWheelDirection.wheelSignal.add(this.onWheel);
    };

    KV.prototype.setEventsSecond = function() {
      return this.v3.ready.then(this.onV3Loaded);
    };

    KV.prototype.onV1Loaded = function() {
      return this.ready.resolve();
    };

    KV.prototype.onV3Loaded = function() {
      return this.ready.resolve();
    };

    KV.prototype.appear = function() {
      if ($$.KVIgnore.isPlayed) {
        this.v3.pause();
        this.v3.seek(0);
        this.v3.show();
        return _.delay((function(_this) {
          return function() {
            return _this.v3.play();
          };
        })(this), 300);
      } else {
        this.v1.pause();
        this.v1.seek(0);
        this.v1.show();
        return _.delay((function(_this) {
          return function() {
            _this.v1.play();
            return _this.initStartV2Loop();
          };
        })(this), 300);
      }
    };

    KV.prototype.initStartV2Loop = function() {
      return setTimeout((function(_this) {
        return function() {
          _this.v2.show();
          _this.v2.play();
          return _this.startV2Loop();
        };
      })(this), 2800);
    };

    KV.prototype.startV2Loop = function() {
      return this.timer = setInterval((function(_this) {
        return function() {
          return _this.v2.play();
        };
      })(this), 4000);
    };

    KV.prototype.stopV2Loop = function() {
      return clearInterval(this.timer);
    };

    KV.prototype.playForConcept = function() {
      if (this.v3.isLowPowerMode) {
        return $$.MainConceptPosition.canScroll();
      } else {
        this.v3.show();
        this.v3.play();
        this.stopV2Loop();
        return $$.KVIgnore.onPlayed();
      }
    };

    KV.prototype.onWheel = function(direction) {
      if (direction === 1) {
        $$.WatchWheelDirection.wheelSignal.remove(this.onWheel);
        return this.playForConcept();
      }
    };

    return KV;

  })();

  module.exports = KV;

}).call(this);
;}});
this.require.define({"_modules/top/KVHideMotion":function(exports, require, module){(function() {
  var KVHideMotion,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  KVHideMotion = (function() {
    function KVHideMotion(config) {
      this.config = config;
      this.hide = bind(this.hide, this);
      this.show = bind(this.show, this);
      this.setTrigger = bind(this.setTrigger, this);
      this.$container = $('#kv');
      this.tl = gsap.timeline();
      this.setTrigger();
    }

    KVHideMotion.prototype.setTrigger = function() {
      return ScrollTrigger.create({
        trigger: '#concept',
        start: 'bottom 100%',
        end: 'bottom 100%',
        onEnter: (function(_this) {
          return function() {
            return _this.hide();
          };
        })(this),
        onLeave: (function(_this) {
          return function() {
            return _this.hide();
          };
        })(this),
        onLeaveBack: (function(_this) {
          return function() {
            return _this.show();
          };
        })(this),
        onEnterBack: (function(_this) {
          return function() {
            return _this.show();
          };
        })(this)
      });
    };

    KVHideMotion.prototype.show = function() {
      this.tl.clear();
      return this.tl.to(this.$container, .4, {
        autoAlpha: 1,
        ease: Quad.easeOut
      });
    };

    KVHideMotion.prototype.hide = function() {
      this.tl.clear();
      return this.tl.to(this.$container, .4, {
        autoAlpha: 0,
        ease: Quad.easeOut
      });
    };

    return KVHideMotion;

  })();

  module.exports = KVHideMotion;

}).call(this);
;}});
this.require.define({"_modules/top/KVIgnore":function(exports, require, module){(function() {
  var KVIgnore,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  KVIgnore = (function() {
    function KVIgnore() {
      this.onPlayed = bind(this.onPlayed, this);
      this.checkIsPlayed = bind(this.checkIsPlayed, this);
      this.isPlayed = false;
      this.checkIsPlayed();
    }

    KVIgnore.prototype.checkIsPlayed = function() {
      if ($.cookie('isKvPlayed') === '1') {
        this.isPlayed = true;
      }
      if ($$.config.dev === '1') {
        return this.isPlayed = false;
      }
    };

    KVIgnore.prototype.onPlayed = function() {
      return $.cookie('isKvPlayed', 1, {
        expires: 1
      });
    };

    return KVIgnore;

  })();

  module.exports = KVIgnore;

}).call(this);
;}});
this.require.define({"_modules/top/KVVideo":function(exports, require, module){(function() {
  var KVVideo, Video,
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  Video = require('_modules/top/Video');

  KVVideo = (function(superClass) {
    extend(KVVideo, superClass);

    function KVVideo(config) {
      this.config = config;
      KVVideo.__super__.constructor.call(this, this.config);
    }

    return KVVideo;

  })(Video);

  module.exports = KVVideo;

}).call(this);
;}});
this.require.define({"_modules/top/Main":function(exports, require, module){(function() {
  var Main, MainAppearMotion, MainConceptPosition,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  MainConceptPosition = require('_modules/top/MainConceptPosition');

  MainAppearMotion = require('_modules/top/MainAppearMotion');

  Main = (function() {
    function Main(config) {
      this.config = config;
      this.setup = bind(this.setup, this);
      this.$container = $('#main');
      this.setup();
    }

    Main.prototype.setup = function() {
      $$.MainConceptPosition = new MainConceptPosition();
      return $$.MainAppearMotion = new MainAppearMotion();
    };

    return Main;

  })();

  module.exports = Main;

}).call(this);
;}});
this.require.define({"_modules/top/MainAppearMotion":function(exports, require, module){(function() {
  var MainAppearMotion, TextSlideAnimation,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  TextSlideAnimation = require('_modules/common/TextSlideAnimation');

  MainAppearMotion = (function() {
    function MainAppearMotion(config) {
      this.config = config;
      this.onFinished = bind(this.onFinished, this);
      this.kill = bind(this.kill, this);
      this.appear = bind(this.appear, this);
      this.setMotionOther = bind(this.setMotionOther, this);
      this.setMotionTitleLogo = bind(this.setMotionTitleLogo, this);
      this.setMotionKVSecond = bind(this.setMotionKVSecond, this);
      this.setMotionKV = bind(this.setMotionKV, this);
      this.setup = bind(this.setup, this);
      this.$container = $('#main');
      this.finished = $.Deferred();
    }

    MainAppearMotion.prototype.setup = function() {
      var delay;
      this.tl = gsap.timeline({
        onComplete: this.onFinished
      }).pause();
      this.tl.call($$.KV.appear, null, 0);
      if ($$.KVIgnore.isPlayed) {
        this.setMotionKVSecond();
        delay = 0.5;
        this.setMotionTitleLogo(delay);
        delay += 1.2;
        this.setMotionOther(delay);
        return this.tl.call($$.MainConceptPosition.canScroll, null, '0+=' + delay);
      } else {
        this.setMotionKV();
        delay = 1.5;
        this.setMotionTitleLogo(delay);
        delay += 1.2;
        return this.setMotionOther(delay);
      }
    };

    MainAppearMotion.prototype.setMotionKV = function() {
      var duration, ease, fromScale;
      if ($$.mode === 'pc') {
        fromScale = 1.3;
        ease = Quad.easeOut;
        duration = 1.8;
      } else {
        fromScale = 1.6;
        ease = Quint.easeOut;
        duration = 2.2;
      }
      return this.tl.fromTo($('#kv'), duration, {
        scale: fromScale
      }, {
        scale: 1,
        ease: ease
      }, 0);
    };

    MainAppearMotion.prototype.setMotionKVSecond = function() {
      this.tl.fromTo($('#kv'), 1.2, {
        alpha: 0
      }, {
        alpha: 1,
        ease: Quad.easeOut
      }, 0);
      return this.tl.fromTo($('#kv'), 1.5, {
        scale: 1.2
      }, {
        scale: 1,
        ease: Quint.easeOut
      }, 0);
    };

    MainAppearMotion.prototype.setMotionTitleLogo = function(delay) {
      this.tl.fromTo($('.header-logo'), 1, {
        alpha: 0
      }, {
        alpha: 1,
        ease: Quad.easeOut,
        delay: delay
      }, 0);
      this.mainTitleEn = new TextSlideAnimation($('.main-title-en'));
      this.tl.add(this.mainTitleEn.getMotion(), '0+=' + delay);
      this.mainTitleJp = new TextSlideAnimation($('.main-title-jp'));
      return this.tl.add(this.mainTitleJp.getMotion(), '0+=' + Number(delay + 0.3));
    };

    MainAppearMotion.prototype.setMotionOther = function(delay) {
      return this.tl.fromTo($('.header-entry, .scrolldown'), 1, {
        alpha: 0
      }, {
        alpha: 1,
        ease: Quad.easeOut,
        delay: delay
      }, 0);
    };

    MainAppearMotion.prototype.appear = function() {
      return _.delay((function(_this) {
        return function() {
          if (_this.tl) {
            return _this.tl.play();
          }
        };
      })(this), 10);
    };

    MainAppearMotion.prototype.kill = function() {
      this.tl.progress(1);
      return this.onFinished();
    };

    MainAppearMotion.prototype.onFinished = function() {
      this.kill = function() {};
      this.onFinished = function() {};
      this.tl.kill();
      this.tl = null;
      return this.finished.resolve();
    };

    return MainAppearMotion;

  })();

  module.exports = MainAppearMotion;

}).call(this);
;}});
this.require.define({"_modules/top/MainConceptPosition":function(exports, require, module){(function() {
  var MainConceptPosition,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  MainConceptPosition = (function() {
    function MainConceptPosition(config) {
      this.config = config;
      this.onResize = bind(this.onResize, this);
      this.canScroll = bind(this.canScroll, this);
      this.onVideo3Playing = bind(this.onVideo3Playing, this);
      this.unsetMainScroll = bind(this.unsetMainScroll, this);
      this.setMainScroll = bind(this.setMainScroll, this);
      this.setConceptPosition = bind(this.setConceptPosition, this);
      this.changeLocalAnchorConcept = bind(this.changeLocalAnchorConcept, this);
      this.onConceptAppeared = bind(this.onConceptAppeared, this);
      this.setAppearConceptMotion = bind(this.setAppearConceptMotion, this);
      this.setEvents = bind(this.setEvents, this);
      this.setup = bind(this.setup, this);
      this.$html = $('html').addClass('-fixedToTop');
      this.$container = $('#main');
      this.$title = $('.main-title');
      this.$concept = $('#concept');
      this.conceptMarginTopDefault = this.$concept.position().top;
      if ($$.mode === 'pc') {
        this.canScrollThreshold = 0.75;
      } else {
        this.canScrollThreshold = 0.7;
      }
      this.setup();
      this.setEvents();
    }

    MainConceptPosition.prototype.setup = function() {
      return this.setAppearConceptMotion();
    };

    MainConceptPosition.prototype.setEvents = function() {
      $$.resizeSignal.add(this.onResize);
      if (!$$.KVIgnore.isPlayed) {
        return $$.KV.v3.playingSignal.add(this.onVideo3Playing);
      }
    };

    MainConceptPosition.prototype.setAppearConceptMotion = function() {
      var delay;
      this.appearConceptTl = gsap.timeline({
        onComplete: this.onConceptAppeared
      }).pause();
      this.appearConceptTl.fromTo(this.$concept, 1, {
        alpha: 0
      }, {
        alpha: 1,
        ease: Quad.easeOut
      }, 0);
      this.appearConceptTl.fromTo(this.$concept, 1.6, {
        y: $$.util.getResponsivePx(80)
      }, {
        y: 0,
        ease: Quint.easeOut
      }, 0);
      delay = 0.6;
      this.appearConceptTl.fromTo($('#menu'), 0.8, {
        y: (function(_this) {
          return function() {
            return $$.util.getResponsivePx(-86);
          };
        })(this)
      }, {
        y: 0,
        ease: Expo.easeOut,
        delay: delay
      }, 0);
      return this.appearConceptTl.fromTo($('#menu-btn'), 0.4, {
        alpha: 0
      }, {
        alpha: 1,
        ease: Quad.easeOut,
        delay: delay
      }, 0);
    };

    MainConceptPosition.prototype.onConceptAppeared = function() {};

    MainConceptPosition.prototype.changeLocalAnchorConcept = function() {
      var href, t;
      t = $('.gnav-anchor-concept');
      href = t.attr('href');
      href = href.replace('concept', 'top');
      return t.attr('href', href);
    };

    MainConceptPosition.prototype.setConceptPosition = function() {
      var mt;
      mt = $$.clientHeight - this.threshold;
      return this.$concept.css({
        'margin-top': -mt + 'px'
      });
    };

    MainConceptPosition.prototype.setMainScroll = function(st) {
      return this.$container.css({
        'top': st.start
      }).addClass('-scroll');
    };

    MainConceptPosition.prototype.unsetMainScroll = function() {
      return this.$container.css({
        'top': ''
      }).removeClass('-scroll');
    };

    MainConceptPosition.prototype.onVideo3Playing = function(currentObj) {
      if (currentObj.progress > this.canScrollThreshold) {
        return this.canScroll();
      }
    };

    MainConceptPosition.prototype.canScroll = function() {
      if (!$$.KVIgnore.isPlayed) {
        $$.KV.v3.playingSignal.remove(this.onVideo3Playing);
      }
      this.appearConceptTl.play();
      $$.ScrollDown.hide();
      $$.WatchWheelDirection.stop();
      this.$html.removeClass('-fixedToTop');
      $(window).trigger('resize');
      return ScrollTrigger.refresh();
    };

    MainConceptPosition.prototype.onResize = function(w, h) {
      var gap;
      if ($$.mode === 'pc') {
        gap = 110;
      } else {
        gap = 120;
      }
      this.threshold = ($$.clientHeight + this.$title.outerHeight()) * 0.5 + $$.util.getResponsivePx(gap);
      return this.setConceptPosition();
    };

    return MainConceptPosition;

  })();

  module.exports = MainConceptPosition;

}).call(this);
;}});
this.require.define({"_modules/top/News":function(exports, require, module){(function() {
  var News, NewsMore,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  NewsMore = require('_modules/top/NewsMore');

  News = (function() {
    function News(config) {
      this.config = config;
      this.setup = bind(this.setup, this);
      this.setup();
    }

    News.prototype.setup = function() {
      return new NewsMore();
    };

    return News;

  })();

  module.exports = News;

}).call(this);
;}});
this.require.define({"_modules/top/NewsMore":function(exports, require, module){(function() {
  var NewsMore,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  NewsMore = (function() {
    function NewsMore(config) {
      this.config = config;
      this.hideButton = bind(this.hideButton, this);
      this.open = bind(this.open, this);
      this.setup = bind(this.setup, this);
      this.$container = $('.news-more').addClass('-backstage');
      this.$button = $('.news-btn-more');
      this.$contents = $('.news-list-more');
      this.setup();
    }

    NewsMore.prototype.setup = function() {
      return this.$button.on('click', this.open);
    };

    NewsMore.prototype.open = function() {
      gsap.fromTo(this.$container, 0.8, {
        height: this.$container.height()
      }, {
        height: this.$contents.height(),
        ease: Expo.easeInOut,
        onComplete: (function(_this) {
          return function() {
            _this.$container.height('');
            return _this.$container.removeClass('-opening');
          };
        })(this)
      });
      gsap.fromTo(this.$contents, 0.8, {
        height: 0
      }, {
        height: this.$contents.height(),
        ease: Expo.easeInOut,
        onComplete: (function(_this) {
          return function() {
            return _this.$contents.height('');
          };
        })(this)
      });
      this.$container.removeClass('-backstage').addClass('-opening');
      return this.hideButton();
    };

    NewsMore.prototype.hideButton = function() {
      return gsap.to(this.$button, 0.4, {
        alpha: 0,
        ease: Expo.easeOut,
        onComplete: (function(_this) {
          return function() {
            return _this.$button.hide();
          };
        })(this)
      });
    };

    return NewsMore;

  })();

  module.exports = NewsMore;

}).call(this);
;}});
this.require.define({"_modules/top/ScrollDown":function(exports, require, module){(function() {
  var ScrollDown,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  ScrollDown = (function() {
    function ScrollDown(config) {
      this.config = config;
      this.hide = bind(this.hide, this);
    }

    ScrollDown.prototype.hide = function() {
      return $('.scrolldown-container').addClass('-hide');
    };

    return ScrollDown;

  })();

  module.exports = ScrollDown;

}).call(this);
;}});
this.require.define({"_modules/top/ScrollEvents":function(exports, require, module){(function() {
  var ScrollEvents,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  ScrollEvents = (function() {
    function ScrollEvents(config) {
      this.config = config;
      this.onResize = bind(this.onResize, this);
      this.onScroll = bind(this.onScroll, this);
      this.createSlideControlOnScroll = bind(this.createSlideControlOnScroll, this);
      this.fixFootSpace = bind(this.fixFootSpace, this);
      this.setTriggerAfterOpening = bind(this.setTriggerAfterOpening, this);
      this.setup = bind(this.setup, this);
      this.stList = [];
    }

    ScrollEvents.prototype.setup = function() {
      this.fixFootSpace();
      this.createSlideControlOnScroll('#lineup', $$.LineupCarousel, {
        start: 'top 60%'
      });
      if ($$.mode === 'sp') {
        return;
      }
      return this.createSlideControlOnScroll('.footer-pagetop', $$.PageTop);
    };

    ScrollEvents.prototype.setTriggerAfterOpening = function() {
      return this.createSlideControlOnScroll('.main-shadow', $$.ScrollDown);
    };

    ScrollEvents.prototype.fixFootSpace = function() {
      return ScrollTrigger.create({
        id: 'fixFootSpace',
        scroller: $$.VirtualScroll.scroller,
        trigger: '#contact',
        start: 'top bottom',
        end: 'bottom top',
        once: true,
        onEnter: (function(_this) {
          return function() {
            return $$.VirtualScroll.update();
          };
        })(this)
      });
    };

    ScrollEvents.prototype.createSlideControlOnScroll = function(target, module, options) {
      var o, st;
      if (options == null) {
        options = {};
      }
      o = _.defaults(options, {
        id: target.replace(/\.|#/, ''),
        scroller: $$.VirtualScroll.scroller,
        trigger: target,
        start: 'top bottom',
        end: 'bottom top',
        onEnter: (function(_this) {
          return function() {
            return module.play();
          };
        })(this),
        onLeave: (function(_this) {
          return function() {
            return module.pause();
          };
        })(this),
        onLeaveBack: (function(_this) {
          return function() {
            return module.pause();
          };
        })(this),
        onEnterBack: (function(_this) {
          return function() {
            return module.play();
          };
        })(this)
      });
      st = ScrollTrigger.create(o);
      this.stList.push(st);
      return st;
    };

    ScrollEvents.prototype.onScroll = function(st) {};

    ScrollEvents.prototype.onResize = function(w, h) {};

    return ScrollEvents;

  })();

  module.exports = ScrollEvents;

}).call(this);
;}});
this.require.define({"_modules/top/Video":function(exports, require, module){(function() {
  var Video,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  Video = (function() {
    function Video(config) {
      this.config = config;
      this.hide = bind(this.hide, this);
      this.show = bind(this.show, this);
      this.setListenerCurrentTime = bind(this.setListenerCurrentTime, this);
      this.getCurrentProgress = bind(this.getCurrentProgress, this);
      this.seek = bind(this.seek, this);
      this.pause = bind(this.pause, this);
      this.play = bind(this.play, this);
      this.setPoster = bind(this.setPoster, this);
      this.onVideoError = bind(this.onVideoError, this);
      this.canPlayVideoOnThisDevice = bind(this.canPlayVideoOnThisDevice, this);
      this.onCanplayThrough = bind(this.onCanplayThrough, this);
      this.setVideoEvent = bind(this.setVideoEvent, this);
      this.createVideo = bind(this.createVideo, this);
      this.setup = bind(this.setup, this);
      this.video = this.config.video;
      this.poster = this.config.poster || null;
      this.$video = $(this.video);
      this.isLowPowerMode = false;
      this.ready = $.Deferred();
      this.setup();
    }

    Video.prototype.setup = function() {
      return this.createVideo();
    };

    Video.prototype.createVideo = function() {
      var source, src;
      if ($$.mode === 'sp' && this.config.srcSp) {
        src = this.config.srcSp;
      } else {
        src = this.config.src;
      }
      source = $('<source>');
      source.attr({
        'src': src,
        'type': 'video/mp4'
      });
      $(this.video).append(source);
      this.video.addEventListener('canplaythrough', this.onCanplayThrough);
      return this.setVideoEvent();
    };

    Video.prototype.setVideoEvent = function() {
      var video_promise;
      video_promise = this.video.play();
      return video_promise.then(this.canPlayVideoOnThisDevice)["catch"](this.onVideoError);
    };

    Video.prototype.onCanplayThrough = function(e) {
      this.video.removeEventListener('canplaythrough', this.onCanplayThrough);
      this.ready.resolve();
      return this.duration = this.video.duration;
    };

    Video.prototype.canPlayVideoOnThisDevice = function() {
      this.pause();
      return this.video.currentTime = 0;
    };

    Video.prototype.onVideoError = function(error) {
      this.isLowPowerMode = true;
      this.video.removeAttribute('autoplay');
      this.hide();
      this.setPoster();
      return this.ready.resolve();
    };

    Video.prototype.setPoster = function() {
      if (!this.poster) {
        return;
      }
      return lazySizes.loader.unveil(this.poster);
    };

    Video.prototype.play = function() {
      if (this.isLowPowerMode) {
        return;
      }
      return this.video.play();
    };

    Video.prototype.pause = function() {
      if (this.isLowPowerMode) {
        return;
      }
      return this.video.pause();
    };

    Video.prototype.seek = function(progress) {
      return this.video.currentTime = this.duration * progress;
    };

    Video.prototype.getCurrentProgress = function() {
      return this.video.currentTime / this.duration;
    };

    Video.prototype.setListenerCurrentTime = function() {
      this.playingSignal = new signals.Signal();
      return this.video.addEventListener('timeupdate', (function(_this) {
        return function() {
          var currentTime;
          currentTime = _this.video.currentTime;
          return _this.playingSignal.dispatch({
            currentTime: currentTime,
            progress: currentTime / _this.duration
          });
        };
      })(this));
    };

    Video.prototype.show = function() {
      return this.$video.show();
    };

    Video.prototype.hide = function() {
      return this.$video.hide();
    };

    return Video;

  })();

  module.exports = Video;

}).call(this);
;}});
this.require.define({"_modules/top/WatchWheelDirection":function(exports, require, module){(function() {
  var WatchWheelDirection,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  WatchWheelDirection = (function() {
    function WatchWheelDirection(config) {
      this.config = config;
      this.stop = bind(this.stop, this);
      this.start = bind(this.start, this);
      this.onSwipedown = bind(this.onSwipedown, this);
      this.onSwipeup = bind(this.onSwipeup, this);
      this.onTouchmove = bind(this.onTouchmove, this);
      this._onWheel = bind(this._onWheel, this);
      this.onWheel = bind(this.onWheel, this);
      this.setup = bind(this.setup, this);
      this.$body = $('body');
      this.wheelSignal = new signals.Signal();
      this.setup();
    }

    WatchWheelDirection.prototype.setup = function() {};

    WatchWheelDirection.prototype.onWheel = function(event) {
      event.preventDefault();
      return this._onWheel(event.deltaY);
    };

    WatchWheelDirection.prototype._onWheel = function(deltaY) {
      if (deltaY > 0.1) {
        this.wheelSignal.dispatch(1);
      }
      if (deltaY < 0.1) {
        return this.wheelSignal.dispatch(-1);
      }
    };

    WatchWheelDirection.prototype.onTouchmove = function(event) {
      event.preventDefault();
      return this._onWheel(event.touches[0].pageY);
    };

    WatchWheelDirection.prototype.onSwipeup = function(e, data) {
      return this._onWheel(1);
    };

    WatchWheelDirection.prototype.onSwipedown = function(e, data) {
      return this._onWheel(-1);
    };

    WatchWheelDirection.prototype.start = function() {
      window.addEventListener('wheel', this.onWheel, {
        passive: false
      });
      window.addEventListener('touchmove', this.onTouchmove, {
        passive: false
      });
      this.$body.on('swipeup', this.onSwipeup);
      return this.$body.on('swipedown', this.onSwipedown);
    };

    WatchWheelDirection.prototype.stop = function() {
      window.removeEventListener('wheel', this.onWheel, {
        passive: false
      });
      window.removeEventListener('touchmove', this.onTouchmove, {
        passive: false
      });
      this.$body.off('swipeup', this.onSwipeup);
      return this.$body.off('swipedown', this.onSwipedown);
    };

    return WatchWheelDirection;

  })();

  module.exports = WatchWheelDirection;

}).call(this);
;}});
(function() {
  var GrandFather;

  GrandFather = require('_modules/top/GrandFather');

  $$.GrandFather = new GrandFather();

  $.when($$.windowLoaded, $$.KV.ready).always((function(_this) {
    return function() {
      return $$.initialAssetsLoadedSignal.dispatch();
    };
  })(this));

}).call(this);
